<template>
  <div class="merchprdfreeshipbulk">
    	<div class="row">
			<div id="content" class="col-sm-12">
				<div class="page-login">
					<div class="account-border">
						<div class="row">
								<div class="col-lg-12 col-md-12 col-sm-12">
									<h2>Product Stock Configure Freeshipping Bulk Upload</h2>
                        <p style="margin-bottom:0px; margin-top:5px; color:#0c8bcc; font-size:14px;">Format .csv .xlsx .xls</p>
                        <br/>
                        <input type="file" ref="prdbulkfile" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" value="">
                        <a v-if="dowXlUrl" style="margin-bottom:0px; margin-top:5px; color:#0c8bcc; font-size:14px;" :href="dowXlUrl" >Download Sample File</a>
                        <a v-if="!dowXlUrl" style="margin-bottom:0px; margin-top:5px; color:#0c8bcc; font-size:14px;">No Sample File</a>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 bulk-upload-btn mb-5" style="margin-botom: 20px;">
                        <button class="btn btn-sm merchprof-sbtn" :disabled="isSubmited" @click="uploadStockBulkFile()" style="margin-bottom: 15px;">Upload</button>
                    </div>     
						</div>
					</div>					
				</div>
			</div>
		</div>
  </div>
</template>
<script>
import mixinProduct from "@/mixins/merchant/APIProduct";
import mixinStore from "@/mixins/merchant/APIStore";
import Domain from "@/mixins/domain";
export default {
  mixins: [Domain, mixinProduct, mixinStore],
  data: function () {
    return {
        dowXlUrl: '',
        selectedFile: null,
        sId: "",
        isSubmited: false,
    }
  },
  created(){
    // this.haddleGetStockXL();
    this.handleGetStores();
  },
  watch: {
    // '$store.state.merchant_selectedstore': function() {
    //     this.haddleGetStockXL();
    // }
  },
  methods: {
    handleGetStores: async function() {
      try {
        let response = await this.getStores();
        this.sId = response[0]._id;
        if (this.sId) {
          this.haddleGetStockXL(this.sId)
        }
      } catch (error) {
        console.log(error);
      }
    },
    haddleGetStockXL: async function(id) {
        try {
            this.dowXlUrl = ''
            let domainUrl = Domain.APP_DOMAIN.split("api/v1/")[0];
            // if (this.$store.state.merchant_selectedstore === '-1') {
            let response = await this.getStockUploadFreeshippingBulk(id);
            if (response.url) {
                this.dowXlUrl = domainUrl + response.url;
            }
            // }
            domainUrl = "";
        } catch (error) {
            this.$swal.fire({
                position: 'center',
                type: 'error',
                title: error.message,
                showConfirmButton: false,
                timer: 1500
            }); 
        }
    },
    uploadStockBulkFile: async function() {
        this.selectedFile = this.$refs.prdbulkfile.files[0];
        const fd = new FormData();  
        fd.append('xl_file',this.selectedFile,this.selectedFile.name); 
        try {
            this.isSubmited = true;
            let response = await this.postUploadFreeshippingStockBulk(fd);
            this.haddleGetStockXL(this.sId);
            this.$swal.fire({
                position: 'center',
                type: 'success',
                title: 'Product Bulk Uploaded!',
                showConfirmButton: false,
                timer: 1500
            }); 
            this.$refs.prdbulkfile.value = "";
            this.isSubmited = false;
        } catch (error) {
            this.$swal.fire({
              position: 'center',
              type: 'error',
              title: error.message,
              showConfirmButton: false,
              timer: 1500
            });
            this.isSubmited = false; 
        }
    }
  }
}
</script>
<style>
.merchprdfreeshipbulk .merchprof-sbtn{
  background:#5779ae !important;
  color: #ffffff;
  border: none;
}
.merchprdfreeshipbulk .merchprof-sbtn:hover{
  color: #000000;
}
.bulk-upload-btn{
    padding-top: 15px;
    text-align: right;;
}
.bulk-instruction{
    margin-bottom: 25px;
    font-weight: 600;
}
</style>