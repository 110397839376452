<template>
  <div>
    	<div class="row">
			<div id="content" class="col-sm-12">
				<div class="page-login">
					<div class="account-border">
						<div class="row">
								<div class="col-lg-12 col-md-12 col-sm-12">
									<h2>Product Stock Configure Bulk Upload sub</h2>
                                    <p style="margin-bottom:0px; margin-top:5px; color:#0c8bcc; font-size:14px;">Format .csv .xlsx .xls</p>
                                    <br/>
                                    <input type="file" ref="prdbulkfile" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" value="">
                           
                                    <a v-if="dowXlUrl" style="margin-bottom:0px; margin-top:5px; color:#0c8bcc; font-size:14px;" :href="dowXlUrl" >Download Sample File</a>
                                    <a v-if="!dowXlUrl" style="margin-bottom:0px; margin-top:5px; color:#0c8bcc; font-size:14px;">No Sample File</a>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 bulk-upload-btn mb-5" style="margin-botom: 20px;">
                                    <button class="btn btn-sm merchprof-sbtn" :disabled="isSubmited" @click="uploadStockBulkFile()" style="margin-bottom: 15px;">Upload</button>
                                </div>     
						</div>
					</div>					
				</div>
			</div>
		</div>
  </div>
</template>
<script>
import mixinProduct from "@/mixins/merchant/APIProduct";
import Domain from "@/mixins/domain";
export default {
  mixins: [Domain,mixinProduct],
  data: function () {
    return {
        dowXlUrl: '',
        selectedFile: null,
        isSubmited: false,
    }
  },
  created(){
    this.haddleGetStockXL();
  },
  watch: {
    '$store.state.merchant_selectedstore': function(id) {
        this.haddleGetStockXL();
    }
  },
  methods: {
    haddleGetStockXL: async function() {
        try {
            this.dowXlUrl = ''
            this.isSubmited = true;
            let domainUrl = Domain.APP_DOMAIN.split("api/v1/")[0];
            if (this.$store.state.merchant_selectedstore !== '-1') {
                let response = await this.getStockUploadBulkSub(this.$store.state.merchant_selectedstore);
                if (response.url) {
                    this.dowXlUrl = domainUrl + response.url;
                }
            }
            domainUrl = "";
            this.isSubmited = false; 
        } catch (error) {
            this.$swal.fire({
                position: 'center',
                type: 'error',
                title: error.message,
                showConfirmButton: false,
                timer: 1500
            });
            this.isSubmited = false; 
        }
    },
    uploadStockBulkFile: async function() {
        this.selectedFile = this.$refs.prdbulkfile.files[0];
        const fd = new FormData();  
        fd.append('xl_file',this.selectedFile,this.selectedFile.name); 
        try {
            let response = await this.postUploadStockBulk(fd, this.$store.state.merchant_selectedstore);
            this.haddleGetStockXL();
            this.$swal.fire({
                position: 'center',
                type: 'success',
                title: 'Product Bulk Uploaded!',
                showConfirmButton: false,
                timer: 1500
            }); 
            this.$refs.prdbulkfile.value = "";
        } catch (error) {
            this.$swal.fire({
                position: 'center',
                type: 'error',
                title: error.message,
                showConfirmButton: false,
                timer: 1500
            }); 
        }
    }
  }
}
</script>
<style>
.merchprof-sbtn{
  background: #ffd338;
  color: #000000;
  border: none;
}
.merchprof-sbtn:hover{
  color: #ffffff;
  background: #ffd338;
}
.bulk-upload-btn{
    padding-top: 15px;
    text-align: right;;
}
.bulk-instruction{
    margin-bottom: 25px;
    font-weight: 600;
}
</style>